import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';


function progressbar(id) {
 
    switch(id){
case 1:
  return (
    
    <CircularProgress color="inherit"/> 
    
    )
    break
    
    case 2:
      return(
        <div>Loading...</div>
      )
      break

    default :
    return(
      <div>Loading...</div>
    )
    break
}
}

export default progressbar
