import React, { useEffect, useState } from "react";
// import "../../Data_Migration/app.css";

function CustomTelerikPagination(props) {
  const [inputPage, setInputPage] = useState(
    props.page.skip / props.page.take + 1
  );
  // Calculate the total number of pages
  const totalPages = Math.ceil(props.totalPages / props.page.take);
  useEffect(() => {
    setInputPage(props.page.skip / props.page.take + 1);
  }, [props.page]);

  const handlePageChange = (action) => {

    let newPage = props.page.skip / props.page.take + 1;
    if (action === "first") newPage = 1;
    if (action === "prev" && newPage > 1) newPage -= 1;
    if (action === "next" && newPage < totalPages) newPage += 1;
    if (action === "last") newPage = totalPages;
    if (typeof action === "number") newPage = action;

    props.pageChange(newPage);
  };

  // const handleInputChange = (event) => {
  //   const newPage = parseInt(event.target.value, 10);
  //   if (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages) {
  //     setInputPage(newPage);
  //   }
  // };
  const handleInputChange = (event) => {
    const newPage = event.target.value;
    setInputPage(newPage); // Update the input value immediately

    // Only update the page if the input is a valid number within the range
    if (newPage === '' || (!isNaN(newPage) && newPage >= 1 && newPage <= totalPages)) {
      setInputPage(newPage);
    }
  };

  const handleInputKeyDown = (event) => {
    if (event.key === "Enter") {
      // Trigger the page change if the current input is a valid page number
      if (!isNaN(inputPage) && inputPage >= 1 && inputPage <= totalPages) {
        handlePageChange(parseInt(inputPage, 10));
      }
    }
  };

  const reloadGrid = () => {
    debugger
    // Call the pageChange function with the current page to refresh the data
    props.pageChange(inputPage);
  };
  return (
    <div className="" style={props.colStyle}>
      <div className="emrgrid" style={props.emrStyle}>
        <div className="pDiv">
          <div className="pDiv2">
            {props.style1 ? (
              <div style={props.style1}>
                <div className="pGroup">
                  <div
                    className="pFirst pButton"
                    onClick={() => handlePageChange("first")}
                  >
                    <span></span>
                  </div>
                  <div
                    className="pPrev pButton"
                    onClick={() => handlePageChange("prev")}
                  >
                    <span></span>
                  </div>
                </div>
                <div className="pGroup">
                  <span className="pcontrol">
                    Page{" "}
                    <input
                      type="text"
                      size="4"
                      value={inputPage}
                      onChange={handleInputChange}
                      onKeyDown={handleInputKeyDown}
                    />{" "}
                    of <span>{totalPages}</span>
                  </span>
                </div>
                <div className="pGroup">
                  <div
                    className="pNext pButton"
                    onClick={() => handlePageChange("next")}
                  >
                    <span></span>
                  </div>
                  <div
                    className="pLast pButton"
                    onClick={() => handlePageChange("last")}
                  >
                    <span></span>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="pGroup">
                  <div
                    className="pFirst pButton"
                    onClick={() => handlePageChange("first")}
                  >
                    <span></span>
                  </div>
                  <div
                    className="pPrev pButton"
                    onClick={() => handlePageChange("prev")}
                  >
                    <span></span>
                  </div>
                </div>
                <div className="pGroup">
                  <span className="pcontrol">
                    Page{" "}
                    <input
                      type="text"
                      size="4"
                      value={inputPage}
                      onChange={(e)=>handleInputChange(e)}
                      onKeyDown={handleInputKeyDown}
                    />{" "}
                    of <span>{totalPages}</span>
                  </span>
                </div>
                <div className="pGroup">
                  <div
                    className="pNext pButton"
                    onClick={() => handlePageChange("next")}
                  >
                    <span></span>
                  </div>
                  <div
                    className="pLast pButton"
                    onClick={() => handlePageChange("last")}
                  >
                    <span></span>
                  </div>
                </div>
              </>
            )}
          </div>
          <div class="pGroup w150" style={props.style}>
            <div class="pReload pButton" onClick={reloadGrid}>
              <span></span>
            </div>

            <span className="pPageStat">
              {totalPages === 0 ? (
                "No Item"
              ) : (
                <>
                  Displaying {props.page.skip + 1} to{" "}
                  {Math.min(
                    props.page.take + props.page.skip,
                    props.totalPages
                  )}{" "}
                  of {props.totalPages} items
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomTelerikPagination;
