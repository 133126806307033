

function DetectEnvironment(isDownload) {

    let URL = "";

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        // dev code
        // URL = "https://BE-EDIPortal.practiceehr.com" 
        URL =  'https://localhost:5001'//"https://localhost:5001"
    }
     else {

        if(isDownload == true){
            URL = "https://BE-EDIPortal.practiceehr.com"
        }else{
            URL = "https://BE-EDIPortal.practiceehr.com"
        }

        // production code
       
    }

    return URL;
}


export default DetectEnvironment;