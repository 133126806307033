

import React, { useEffect, useState, useRef } from 'react';
import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import progressbar from 'src/views/progressbar/progressbar'
import UserProfile from 'src/containers/GlobalSettings/UserProfile';
import 'src/scss/Kendo.css';
import axios from 'src/containers/GlobalSettings/axios';
import Claim_a from "src/assets/resource/Claim_a.png"
import Claim from "src/assets/resource/Claim.png"
import ClaimDisable from "src/assets/resource/ClaimDisable.png"

import ClaimStatus_a from "src/assets/resource/ClaimStatus_a.png"
import ClaimStatus_c from "src/assets/resource/ClaimStatus_c.png"
import ClaimStatus_d from "src/assets/resource/ClaimStatus_d.png"

import ERAGrid_c from "src/assets/resource/ERAGrid_c.png"
import ERAGrid_e from "src/assets/resource/ERAGrid_e.png"
import ERAGrid_f from "src/assets/resource/ERAGrid_f.png"

import Eligibility_a from "src/assets/resource/Eligibility_a.png"
import Eligibility from "src/assets/resource/Eligibility.png"
import EligibilityDisable from "src/assets/resource/EligibilityDisable.png"
import {
    CButton,
    CTabs,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CInput
} from '@coreui/react'
import ExportExcelIcon from 'src/assets/resource/icons/Export_Excel_white.png';
import CIcon from '@coreui/icons-react'
import { CSVLink } from "react-csv";
import { request } from 'src/containers/GlobalSettings/request'
import { Tooltip } from '@progress/kendo-react-tooltip';
import DetectEnvironment from 'src/containers/GlobalSettings/DetectEnvironment';
import '@progress/kendo-theme-default/dist/all.css';
import CustomTelerikPagination from '../Common/CustomTelrikPagination';  

const customized_col_names = ["Payer Name", "Payer ID", "Claims(Supported Transactions)", "Claim Status(Supported Transactions)", "ERA(Supported Transactions)", "Eligibility(Supported Transactions)", "Claims(Enrollment Required)", "Claim Status(Enrollment Required)", "ERA(Enrollment Required)", "Eligibility(Enrollment Required)"];

const initialSort = [
    {
        field: "0",
        dir: "desc",
    },
];

const initialDataState = {
    skip: 0,
    take: 100,
};



function KendoPayerList() {

  
    let body_height = document.documentElement.offsetHeight;
    var tableHeight = eval(body_height - 188)-8;



    useEffect(() => {
     
        body_height = document.documentElement.offsetHeight;
        tableHeight = eval(body_height - 160) -8;
    }, []);

   

    const [page, setPage] = useState(initialDataState);
    const [sort, setSort] = useState();

    const [Data1, SetData1] = useState();
    const [Total, SetTotal] = useState();
    const [open, setOpen] = useState(false);
    const [Isloading, SetIsloading] = useState(false);
 
    const [name, setName] = useState();
    const [id, setId] = useState();
    const [filter, setFilter] = useState();

    const [exportData, setExportData] = useState([]);
    const [headers, setHeaders] = useState();

    const csvLink = useRef();

    const [isLoading, setIsLoading] = useState(false);

    const [reRender, setRerender] = useState();
    const [pagination, setpagination] = useState(false);
    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }));

    const classes = useStyles();

    useEffect(() => {

        UserProfile.DashboardItemsRemove();
        UserProfile.setClaimStatus("ALL");
        UserProfile.setClaimDuration("ALL");
        setIsLoading(false);
        setOpen(true);

        if(UserProfile.getPayerListTab()){ }else{
            UserProfile.setPayerListTab(1);
        }


        function getData() {

            fetch(`${DetectEnvironment()}/${request.Get.PayerList}?category=${UserProfile.getPayerListTab() == 1 ? "MEDICAL" : UserProfile.getPayerListTab() == 2 ? "WC" : UserProfile.getPayerListTab() == 3 ? "DENTAL" : "MEDICAL"}&name=${name == "" ? "undefined" : name}&id=${id == "" ? "undefined" : id}&filter=${filter == "" ? "undefined" : filter}&start=${page.skip}&end=${page.take}&db=LiveDB`)
                .then(response => response.json()).catch(error => { alert(error.message); })

                .then(json => {

                    if ("Empty" in json[0].data) {
                        SetData1([]);
                        SetTotal(0)
                    }
                    else {

                        SetData1(json[0].data);
                        setIsLoading(true);
                        if ("Empty" in json[1].data) {
                            if (page.skip == 0) {
                                SetTotal(0)
                            } else { }
                        } else {
                            SetTotal(json[1].data.Total)
                        }

                    }
                    setOpen(false);

                }).catch(error => {
                    alert(error.message);
                })
                .then(setSort(initialSort)).catch(error => {
                    alert(error.message);
                })

                .then(SetIsloading(true)
                ).catch(error => {
                    alert(error.message);
                })

        }

        getData();

    }, [page, reRender]);


    const pageChange = (newPage) => {
        setpagination(true)
        const newSkip = (newPage - 1) * page.take;
        setPage({
          skip: newSkip,
          take: page.take
        });
        setTimeout(() => {
            setpagination(false);
          }, 5000);
        // setPage(event);
    };


    function handleExport(head, url) {


        if ("Empty" in Data1) {
            alert("No Data Found");
        } else {
            setOpen(true);
            let header = [];
            axios.get(url)
                .then(response => {

                    let headerKeys = Object.keys(response.data[0].data[0]);


                    for (let i = 0; i < head.length; i++) {

                        var obj = { label: head[i], key: headerKeys[i] };

                        header.push(obj);
                    }



                    setExportData(response.data[0].data);
                    setHeaders(header);
                    setOpen(false);
                    csvLink.current.link.click();

                })
                .catch(error => {
                    setOpen(false);
                    alert(error.message);
                })
        }

    }

    function HandleSearchItem(){
        setName("");
        setId("");
        document.getElementById('payerId').value = "";
        document.getElementById('payerName').value = "";
    }


        return (
          
                <div className="c-app bg-ground" style={{display:"block", padding:"5px"}}>

                {/* </div> */}
            {/* <> */}
           
                {open ? <Backdrop className={classes.backdrop} open={open} > {progressbar(1)} </Backdrop> : ""}

            
                <CTabs>
                    <CNav >
                        <CNavItem >
                            <CNavLink className={UserProfile.getPayerListTab() == 1 ? "custom_tab" : ""} onClick={() => setPage(initialDataState) || UserProfile.setPayerListTab(1) || setFilter("") || setName("") || setId("") || setRerender(Math.random())}>
                                Professional Payer List
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink className={UserProfile.getPayerListTab() == 2 ? "custom_tab" : ""} onClick={() => setPage(initialDataState) || UserProfile.setPayerListTab(2) || setFilter("") || setName("") || setId("") || setRerender(Math.random())}>
                                Worker Comp and No-Fault Payers List
                            </CNavLink>
                        </CNavItem>
                        <CNavItem>
                            <CNavLink className={UserProfile.getPayerListTab() == 3 ? "custom_tab" : ""} onClick={() => setPage(initialDataState) || UserProfile.setPayerListTab(3) || setFilter("") || setName("") || setId("") || setRerender(Math.random())}>
                                Dental Payer List
                            </CNavLink>

                        </CNavItem>

                    </CNav>
                    <CTabContent>
                        <CTabPane>

                            {Data1 && UserProfile.getPayerListTab() == 1 ?
                                <>
                                    <div className="row w-100">
                                        <div className="col mb-3 col-12 text-center">
                                            <div className="d-flex customHeadTitle">
                                                <h2>PracticeEHR Payer List</h2>
                                                <div className="customHeadButton">
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("undefined") || HandleSearchItem() }} >ALL</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("0") || HandleSearchItem() }}>0-9</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("A") || HandleSearchItem()}}>A</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("B") || HandleSearchItem()}}>B</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("C") || HandleSearchItem()}}>C</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("D") || HandleSearchItem()}}>D</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("E") || HandleSearchItem()}}>E</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("F") || HandleSearchItem()}}>F</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("G") || HandleSearchItem()}}>G</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("H") || HandleSearchItem()}}>H</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("I") || HandleSearchItem()}}>I</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("J") || HandleSearchItem()}}>J</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("K") || HandleSearchItem()}}>K</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("L") || HandleSearchItem()}}>L</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("M") || HandleSearchItem()}}>M</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("N") || HandleSearchItem()}}>N</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("O") || HandleSearchItem()}}>O</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("P") || HandleSearchItem()}}>P</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Q") || HandleSearchItem()}}>Q</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("R") || HandleSearchItem()}}>R</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("S") || HandleSearchItem()}}>S</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("T") || HandleSearchItem()}}>T</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("U") || HandleSearchItem()}}>U</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("V") || HandleSearchItem()}}>V</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("W") || HandleSearchItem()}}>W</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("X") || HandleSearchItem()}}>X</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Y") || HandleSearchItem()}}>Y</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Z") || HandleSearchItem()}}>Z</button>

                                                    {isLoading ? <CButton style={{background:"transparent", marginLeft:"2px"}}    className="ExportExcel-btn" id="WorkQueueExportExcel" onClick={() => handleExport(customized_col_names, `${request.Get.PayerListExport}?category=${UserProfile.getPayerListTab() == 1 ? "MEDICAL" : UserProfile.getPayerListTab() == 2 ? "WC" : UserProfile.getPayerListTab() == 3 ? "DENTAL" : "MEDICAL"}&name=${name == "" ? "undefined" : name}&id=${id == "" ? "undefined" : id}&filter=${filter == "" ? "undefined" : filter}&db=LiveDB`)}>

<CIcon  src={ExportExcelIcon} />

</CButton> : ""}
<CSVLink className="hidden" ref={csvLink} filename={`ProfessionalPayerList.csv`} data={exportData} headers={headers} />

                                                    <div style={{ marginLeft: "auto" }} >
                                                        {/* <p style={{ textAlign: 'end' }}>
                                                            {isLoading ? <CButton  className="ExportExcel-btn" id="WorkQueueExportExcel" onClick={() => handleExport(customized_col_names, `${request.Get.PayerListExport}?category=${UserProfile.getPayerListTab() == 1 ? "MEDICAL" : UserProfile.getPayerListTab() == 2 ? "WC" : UserProfile.getPayerListTab() == 3 ? "DENTAL" : "MEDICAL"}&name=${name == "" ? "undefined" : name}&id=${id == "" ? "undefined" : id}&filter=${filter == "" ? "undefined" : filter}&db=LiveDB`)}>

                                                                <CIcon  src={ExportExcelIcon} />

                                                            </CButton> : ""}
                                                            <CSVLink className="hidden" ref={csvLink} filename={`ProfessionalPayerList.csv`} data={exportData} headers={headers} />
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row direction">
                                        <div className="col-md-6 d-flex">
                                            <CInput id="payerName" placeholder="Search By Name" required />
                                            <CInput id="payerId" placeholder="Search By Payer ID" required />
                                            <CButton onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("undefined") || setName(document.getElementById("payerName").value) || setId(document.getElementById("payerId").value) }} type="submit" color="primary" className="custom_button1">Search</CButton>

                                        </div>
                                    </div>
                                    <Tooltip openDelay={100} position="left" anchorElement="target">
                                        <Grid

                                        style={{
                                            height:tableHeight
                                        }}
                                            reorderable={true}
                                            fixedScroll={true}
                                            className='customGridHeight'
                                            pageable={false}
                                            take={page.take}
                                            skip={page.skip}
                                            total={Total}
                                            {...Data1}
                                            onPageChange={pageChange}
                                            data={Data1} >

                                            <Column field="PayerName" width="300px" title="Payer Name"

                                            />
                                            <Column field="PayerId" width="150px" title="Payer ID" />

                                            <Column width="150px" title="Supported Transactions">

                                                <Column field="" width="150px" title="Claims"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges837"] > 0 ? <td> <img src={Claim_a} /></td> :
                                                                props.dataItem["Is837Supported"] == "Yes" ? <td> <img src={Claim} /></td> :
                                                                    props.dataItem["Is837Supported"] == "No" ? <td> <img src={ClaimDisable} /></td> :
                                                                        " "

                                                        )

                                                    }}

                                                />
                                                <Column field="" width="150px" title="Claim Status"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges276"] > 0 ? <td> <img src={ClaimStatus_a} /></td> :
                                                                props.dataItem["Is276Supported"] == "Yes" ? <td> <img src={ClaimStatus_c} /></td> :
                                                                    props.dataItem["Is276Supported"] == "No" ? <td> <img src={ClaimStatus_d} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                                <Column field="" width="250px" title="ERA"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges835"] > 0 ? <td> <img src={ERAGrid_c} /></td> :
                                                                props.dataItem["Is835Supported"] == "Yes" ? <td> <img src={ERAGrid_e} /></td> :
                                                                    props.dataItem["Is835Supported"] == "No" ? <td> <img src={ERAGrid_f} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                                <Column field="" width="200px" title="Eligibility"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges270"] > 0 ? <td> <img src={Eligibility_a} /></td> :
                                                                props.dataItem["Is270Supported"] == "Yes" ? <td> <img src={Eligibility} /></td> :
                                                                    props.dataItem["Is270Supported"] == "No" ? <td> <img src={EligibilityDisable} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                            </Column>
                                            <Column width="150px" title="Enrollment Required">
                                                <Column field="Is837EnrollmentRequired" width="150px" title="Claims" />
                                                <Column field="Is276EnrollmentRequired" width="150px" title="Claim Status" />
                                                <Column field="Is835EnrollmentRequired" width="150px" title="ERA" />
                                                <Column field="Is270EnrollmentRequired" width="150px" title="Eligibility" />
                                            </Column>
                                        </Grid >
                                        <CustomTelerikPagination
                                pageChange={pageChange}
                                page={page}
                                totalPages={Total}
                                setPage={setPage}
                              />
                                    </Tooltip>
                                </>
                                : ""}
                        </CTabPane>
                        <CTabPane>
                            {Data1 && UserProfile.getPayerListTab() == 2 ?
                                <>
                                    <div className="row w-100">
                                        <div className="col mb-3 col-12 text-center">
                                            <div className="d-flex customHeadTitle">
                                                <h2>PracticeEHR Payer List</h2>
                                                <div className="customHeadButton">
                                                <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("undefined") || HandleSearchItem() }} >ALL</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("0") || HandleSearchItem() }}>0-9</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("A") || HandleSearchItem()}}>A</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("B") || HandleSearchItem()}}>B</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("C") || HandleSearchItem()}}>C</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("D") || HandleSearchItem()}}>D</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("E") || HandleSearchItem()}}>E</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("F") || HandleSearchItem()}}>F</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("G") || HandleSearchItem()}}>G</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("H") || HandleSearchItem()}}>H</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("I") || HandleSearchItem()}}>I</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("J") || HandleSearchItem()}}>J</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("K") || HandleSearchItem()}}>K</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("L") || HandleSearchItem()}}>L</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("M") || HandleSearchItem()}}>M</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("N") || HandleSearchItem()}}>N</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("O") || HandleSearchItem()}}>O</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("P") || HandleSearchItem()}}>P</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Q") || HandleSearchItem()}}>Q</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("R") || HandleSearchItem()}}>R</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("S") || HandleSearchItem()}}>S</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("T") || HandleSearchItem()}}>T</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("U") || HandleSearchItem()}}>U</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("V") || HandleSearchItem()}}>V</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("W") || HandleSearchItem()}}>W</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("X") || HandleSearchItem()}}>X</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Y") || HandleSearchItem()}}>Y</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Z") || HandleSearchItem()}}>Z</button>
                                                    
                                                    {isLoading ? <CButton  style={{background:"transparent", marginLeft:"2px"}}  className="ExportExcel-btn" id="WorkQueueExportExcel" onClick={() => handleExport(customized_col_names, `${request.Get.PayerListExport}?category=${UserProfile.getPayerListTab() == 1 ? "MEDICAL" : UserProfile.getPayerListTab() == 2 ? "WC" : UserProfile.getPayerListTab() == 3 ? "DENTAL" : "MEDICAL"}&name=${name == "" ? "undefined" : name}&id=${id == "" ? "undefined" : id}&filter=${filter == "" ? "undefined" : filter}&db=LiveDB`)}>

<CIcon  src={ExportExcelIcon} />

</CButton> : ""}
<CSVLink className="hidden" ref={csvLink} filename={`WorkerCompandNoFaultyPayerList.csv`} data={exportData} headers={headers} />

                                                    <div style={{ marginLeft: "auto" }} >
                                                        {/* <p style={{ textAlign: 'end' }}>
                                                            {isLoading ? <CButton  style={{background:"transparent", marginLeft:"2px"}}  className="ExportExcel-btn" id="WorkQueueExportExcel" onClick={() => handleExport(customized_col_names, `${request.Get.PayerListExport}?category=${UserProfile.getPayerListTab() == 1 ? "MEDICAL" : UserProfile.getPayerListTab() == 2 ? "WC" : UserProfile.getPayerListTab() == 3 ? "DENTAL" : "MEDICAL"}&name=${name == "" ? "undefined" : name}&id=${id == "" ? "undefined" : id}&filter=${filter == "" ? "undefined" : filter}&db=LiveDB`)}>

                                                                <CIcon  src={ExportExcelIcon} />

                                                            </CButton> : ""}
                                                            <CSVLink className="hidden" ref={csvLink} filename={`WorkerCompandNoFaultyPayerList.csv`} data={exportData} headers={headers} />
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row direction">
                                        <div className="col-md-6 d-flex">
                                          <CInput id="payerName" placeholder="Search By Name" required />
                                            <CInput id="payerId" placeholder="Search By Payer ID" required />
                                            <CButton onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("undefined") || setName(document.getElementById("payerName").value) || setId(document.getElementById("payerId").value) }} type="submit" color="primary" className="custom_button1">Search</CButton>

                                        </div>
                                    </div>
                                    <Tooltip openDelay={100} position="left" anchorElement="target">
                                        <Grid
                                          style={{
                                            height:tableHeight
                                        }}
                                            reorderable={true}
                                            fixedScroll={true}
                                            className='customGridHeight'
                                            pageable={false}
                                            take={page.take}
                                            skip={page.skip}
                                            total={Total}
                                            {...Data1}
                                            onPageChange={pageChange}
                                            data={Data1} >

                                            <Column field="PayerName" width="300px" title="Payer Name"

                                            />
                                            <Column field="PayerId" width="150px" title="Payer ID" />

                                            <Column width="150px" title="Supported Transactions">

                                                <Column field="" width="150px" title="Claims"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges837"] > 0 ? <td> <img src={Claim_a} /></td> :
                                                                props.dataItem["Is837Supported"] == "Yes" ? <td> <img src={Claim} /></td> :
                                                                    props.dataItem["Is837Supported"] == "No" ? <td> <img src={ClaimDisable} /></td> :
                                                                        " "

                                                        )

                                                    }}

                                                />
                                                <Column field="" width="150px" title="Claim Status"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges276"] > 0 ? <td> <img src={ClaimStatus_a} /></td> :
                                                                props.dataItem["Is276Supported"] == "Yes" ? <td> <img src={ClaimStatus_c} /></td> :
                                                                    props.dataItem["Is276Supported"] == "No" ? <td> <img src={ClaimStatus_d} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                                <Column field="" width="250px" title="ERA"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges835"] > 0 ? <td> <img src={ERAGrid_c} /></td> :
                                                                props.dataItem["Is835Supported"] == "Yes" ? <td> <img src={ERAGrid_e} /></td> :
                                                                    props.dataItem["Is835Supported"] == "No" ? <td> <img src={ERAGrid_f} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                                <Column field="" width="200px" title="Eligibility"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges270"] > 0 ? <td> <img src={Eligibility_a} /></td> :
                                                                props.dataItem["Is270Supported"] == "Yes" ? <td> <img src={Eligibility} /></td> :
                                                                    props.dataItem["Is270Supported"] == "No" ? <td> <img src={EligibilityDisable} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                            </Column>
                                            <Column width="150px" title="Enrollment Required">
                                                <Column field="Is837EnrollmentRequired" width="150px" title="Claims" />
                                                <Column field="Is276EnrollmentRequired" width="150px" title="Claim Status" />
                                                <Column field="Is835EnrollmentRequired" width="150px" title="ERA" />
                                                <Column field="Is270EnrollmentRequired" width="150px" title="Eligibility" />
                                            </Column>
                                        </Grid >
                                        <CustomTelerikPagination
                                pageChange={pageChange}
                                page={page}
                                totalPages={Total}
                                setPage={setPage}
                              />
                                    </Tooltip>
                                </>
                                : ""}
                        </CTabPane>
                        <CTabPane>
                            {Data1 && UserProfile.getPayerListTab() == 3 ?
                                <>
                                    <div className="row w-100">
                                        <div className="col mb-3 col-12 text-center">
                                            <div className="d-flex customHeadTitle">
                                                <h2>PracticeEHR Payer List</h2>
                                                <div className="customHeadButton">
                                                <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("undefined") || HandleSearchItem() }} >ALL</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("0") || HandleSearchItem() }}>0-9</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("A") || HandleSearchItem()}}>A</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("B") || HandleSearchItem()}}>B</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("C") || HandleSearchItem()}}>C</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("D") || HandleSearchItem()}}>D</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("E") || HandleSearchItem()}}>E</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("F") || HandleSearchItem()}}>F</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("G") || HandleSearchItem()}}>G</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("H") || HandleSearchItem()}}>H</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("I") || HandleSearchItem()}}>I</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("J") || HandleSearchItem()}}>J</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("K") || HandleSearchItem()}}>K</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("L") || HandleSearchItem()}}>L</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("M") || HandleSearchItem()}}>M</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("N") || HandleSearchItem()}}>N</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("O") || HandleSearchItem()}}>O</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("P") || HandleSearchItem()}}>P</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Q") || HandleSearchItem()}}>Q</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("R") || HandleSearchItem()}}>R</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("S") || HandleSearchItem()}}>S</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("T") || HandleSearchItem()}}>T</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("U") || HandleSearchItem()}}>U</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("V") || HandleSearchItem()}}>V</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("W") || HandleSearchItem()}}>W</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("X") || HandleSearchItem()}}>X</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Y") || HandleSearchItem()}}>Y</button> <span>|</span>
                                                    <button onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("Z") || HandleSearchItem()}}>Z</button>
                                                    
                                                    {isLoading ? <CButton style={{background:"transparent", marginLeft:"2px"}}  className="ExportExcel-btn"  id="WorkQueueExportExcel" onClick={() => handleExport(customized_col_names, `${request.Get.PayerListExport}?category=${UserProfile.getPayerListTab() == 1 ? "MEDICAL" : UserProfile.getPayerListTab() == 2 ? "WC" : UserProfile.getPayerListTab() == 3 ? "DENTAL" : "MEDICAL"}&name=${name == "" ? "undefined" : name}&id=${id == "" ? "undefined" : id}&filter=${filter == "" ? "undefined" : filter}&db=LiveDB`)}>

<CIcon  src={ExportExcelIcon} />

</CButton> : ""}
<CSVLink className="hidden" ref={csvLink} filename={`DentalPayerList.csv`} data={exportData} headers={headers} />

                                                    <div style={{ marginLeft: "auto" }} >
                                                        {/* <p style={{ textAlign: 'end' }}>
                                                            {isLoading ? <CButton style={{background:"transparent", marginLeft:"2px"}}  className="ExportExcel-btn"  id="WorkQueueExportExcel" onClick={() => handleExport(customized_col_names, `${request.Get.PayerListExport}?category=${UserProfile.getPayerListTab() == 1 ? "MEDICAL" : UserProfile.getPayerListTab() == 2 ? "WC" : UserProfile.getPayerListTab() == 3 ? "DENTAL" : "MEDICAL"}&name=${name == "" ? "undefined" : name}&id=${id == "" ? "undefined" : id}&filter=${filter == "" ? "undefined" : filter}&db=LiveDB`)}>

                                                                <CIcon  src={ExportExcelIcon} />

                                                            </CButton> : ""}
                                                            <CSVLink className="hidden" ref={csvLink} filename={`DentalPayerList.csv`} data={exportData} headers={headers} />
                                                        </p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row direction">
                                        <div className="col-md-6 d-flex">
                                         <CInput id="payerName" placeholder="Search By Name" required />
                                            <CInput id="payerId" placeholder="Search By Payer ID" required />
                                            <CButton onClick={() => { setPage(initialDataState) || setRerender(Math.random()) || setFilter("undefined") || setName(document.getElementById("payerName").value) || setId(document.getElementById("payerId").value) }} type="submit" color="primary" className="custom_button1">Search</CButton>

                                        </div>
                                    </div>
                                    <Tooltip openDelay={100} position="left" anchorElement="target">
                                        <Grid
                                          style={{
                                            height:tableHeight
                                        }}
                                            reorderable={true}
                                            fixedScroll={true}
                                            className='customGridHeight'
                                            pageable={false}
                                            take={page.take}
                                            skip={page.skip}
                                            total={Total}
                                            {...Data1}
                                            onPageChange={pageChange}
                                            data={Data1} >


                                            <Column field="PayerName" width="300px" title="Payer Name" />
                                            <Column field="PayerId" width="150px" title="Payer ID" />

                                            <Column width="150px" title="Supported Transactions">

                                                <Column field="" width="150px" title="Claims"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges837"] > 0 ? <td> <img src={Claim_a} /></td> :
                                                                props.dataItem["Is837Supported"] == "Yes" ? <td> <img src={Claim} /></td> :
                                                                    props.dataItem["Is837Supported"] == "No" ? <td> <img src={ClaimDisable} /></td> :
                                                                        " "

                                                        )

                                                    }}

                                                />
                                                <Column field="" width="150px" title="Claim Status"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges276"] > 0 ? <td> <img src={ClaimStatus_a} /></td> :
                                                                props.dataItem["Is276Supported"] == "Yes" ? <td> <img src={ClaimStatus_c} /></td> :
                                                                    props.dataItem["Is276Supported"] == "No" ? <td> <img src={ClaimStatus_d} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                                <Column field="" width="250px" title="ERA"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges835"] > 0 ? <td> <img src={ERAGrid_c} /></td> :
                                                                props.dataItem["Is835Supported"] == "Yes" ? <td> <img src={ERAGrid_e} /></td> :
                                                                    props.dataItem["Is835Supported"] == "No" ? <td> <img src={ERAGrid_f} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                                <Column field="" width="200px" title="Eligibility"

                                                    cell={(props) => {


                                                        return (
                                                            props.dataItem["Charges270"] > 0 ? <td> <img src={Eligibility_a} /></td> :
                                                                props.dataItem["Is270Supported"] == "Yes" ? <td> <img src={Eligibility} /></td> :
                                                                    props.dataItem["Is270Supported"] == "No" ? <td> <img src={EligibilityDisable} /></td> :
                                                                        " "

                                                        )

                                                    }}
                                                />
                                            </Column>
                                            <Column width="150px" title="Enrollment Required">
                                                <Column field="Is837EnrollmentRequired" width="150px" title="Claims" />
                                                <Column field="Is276EnrollmentRequired" width="150px" title="Claim Status" />
                                                <Column field="Is835EnrollmentRequired" width="150px" title="ERA" />
                                                <Column field="Is270EnrollmentRequired" width="150px" title="Eligibility" />
                                            </Column>
                                        </Grid >
                                        <CustomTelerikPagination
                                pageChange={pageChange}
                                page={page}
                                totalPages={Total}
                                setPage={setPage}
                              />
                                    </Tooltip>
                                </>
                                : ""}
                        </CTabPane>
                    </CTabContent>
                </CTabs>


                </div>
        

        )
};


export default KendoPayerList;
