import React,{ useState } from "react";
import Logo from 'src/assets/resource/logo.png'

function Loader(props){

    return(

      <div class="row-form">
      <div class="col-12">
        <div>
          <img
            style={{ width: "100px", height: "100px" }}
            src="/Loader.gif"
            alt="fa"
          />
        </div>
      </div>
    </div>
    )

}

export default Loader;