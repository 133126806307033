import axios from "axios";



const prod = {
    // URL : "https://as-edi-portal-be-dev.azurewebsites.net/"
    URL :"https://BE-EDIPortal.practiceehr.com"
}


const dev = {
    //   URL:  "https://BE-EDIPortal.practiceehr.com" 
      URL: 'https://localhost:5001' //"https://localhost:5001"
    //  URL :"https://stagecoreapi.practiceehr.com/DownloadFiles"
}





const URL = process.env.NODE_ENV === 'development' ? dev.URL : prod.URL;

const instance = axios.create({
    baseURL: URL,
});

export default instance ;